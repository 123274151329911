// About.js

import React from 'react';

const About = () => {
  return (
    <div>
      <h2>About Page</h2>
      <p>Learn more about our app here.</p>
    </div>
  );
};

export default About;
